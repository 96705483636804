import React, { useState, useRef } from "react";
import "./App.css";

const App = () => {
  const [file, setFile] = useState(null);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState("");
  const [status, setStatus] = useState("Aguardando envio");
  const [language, setLanguage] = useState("pt-BR");
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setRecordedAudio(null);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
    setFile(null);
  };

  const handleYoutubeSubmit = async () => {
    if (!youtubeUrl) {
      alert("Insira um link do YouTube!");
      return;
    }
  
    if (!language) {
      alert("Selecione um idioma para a transcrição!");
      return;
    }
  
    try {
      setStatus("Baixando vídeo do YouTube...");
      const response = await fetch("/api/youtube", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: youtubeUrl, language }), // 🔥 Agora o idioma é enviado corretamente
      });
  
      const data = await response.json();
      if (response.ok) {
        setStatus("Processando...");
        await fetchResult(data.trans_id);
      } else {
        setStatus(`Erro: ${data.error || "Erro desconhecido"}`);
      }
    } catch (error) {
      console.error("Erro ao processar vídeo do YouTube:", error);
      setStatus("Erro ao processar vídeo do YouTube.");
    }
  };
  

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new AudioContext();
      const mediaStreamSource = audioContext.createMediaStreamSource(stream);
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=pcm',
        audioBitsPerSecond: 16000,
        sampleRate: 16000
      });

      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioContext = new AudioContext();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        
        const wavBuffer = audioBufferToWav(audioBuffer);
        const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
        
        setRecordedAudio(wavBlob);
        setStatus("Áudio gravado com sucesso!");
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
      setStatus("Gravando áudio...");
      setFile(null);
    } catch (error) {
      console.error("Erro ao iniciar gravação:", error);
      setStatus("Erro ao acessar microfone");
    }
  };

  function audioBufferToWav(buffer) {
    const numChannels = buffer.numberOfChannels;
    const sampleRate = buffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;
    const bytesPerSample = bitDepth / 8;
    const blockAlign = numChannels * bytesPerSample;
    const dataLength = buffer.length * numChannels * bytesPerSample;
    const bufferLength = 44 + dataLength;
    const arrayBuffer = new ArrayBuffer(bufferLength);
    const view = new DataView(arrayBuffer);

    // Cabeçalho WAV
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + dataLength, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, format, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * blockAlign, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, 'data');
    view.setUint32(40, dataLength, true);

    const offset = 44;
    const channels = [];
    for (let i = 0; i < numChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }
    
    let position = offset;
    for (let i = 0; i < buffer.length; i++) {
      for (let channel = 0; channel < numChannels; channel++) {
        const sample = Math.max(-1, Math.min(1, channels[channel][i]));
        view.setInt16(position, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
        position += 2;
      }
    }
    
    return arrayBuffer;
  }
  
  function writeString(view, offset, string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  const handleUpload = async () => {
    const audioToUpload = recordedAudio || file;
    if (!audioToUpload) {
      alert("Selecione um arquivo ou grave um áudio!");
      return;
    }

    const formData = new FormData();
    if (recordedAudio) {
      formData.append("file", recordedAudio, "recorded_audio.wav");
    } else {
      formData.append("file", file);
    }
    formData.append("language", language);

    try {
      setStatus("Fazendo upload...");
      const response = await fetch("/api/envio", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setStatus("Processando...");
        await fetchResult(data.trans_id);
      } else {
        setStatus(`Erro: ${data.message || "Erro desconhecido"}`);
      }
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      setStatus("Erro ao fazer upload.");
    }
  };

  const fetchResult = async (transId) => {
        try {
            while (true) {
                const response = await fetch(`/api/progresso/${transId}`);
                if (!response.ok) {
                    setStatus(`Erro ao obter progresso: ${response.statusText}`);
                    break;
                }

                const data = await response.json();
                if (data.progress) {
                    setProgress(data.progress);
                }

                // ✅ Se o progresso for 100% e houver um resultado, exibir e parar polling
                if (data.progress === 100 && data.result && data.result.trim().length > 0) {
                    setResult(data.result);
                    setStatus("Transcrição concluída!");
                    break;
                }

                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        } catch (error) {
            console.error("Erro ao acompanhar progresso:", error);
            setStatus("Erro ao acompanhar progresso.");
        }
    };

  return (
    <div className="app-container">
      <header>
        <h1>Envio de Áudio para Transcrição</h1>
      </header>
      <main>
        <div className="card">
          <section className="language-selector">
            <label htmlFor="language">Selecione o idioma:</label>
            <select id="language" value={language} onChange={handleLanguageChange}>
              <option value="pt-BR">Português</option>
              <option value="en-US">Inglês</option>
              <option value="es-ES">Espanhol</option>
              <option value="fr-FR">Francês</option>
              <option value="de-DE">Alemão</option>
            </select>
          </section>

          <section className="upload-options">
            <div className="option file-upload">
              <h4>Upload de Arquivo</h4>
              <input type="file" accept="*/*" onChange={handleFileChange} />

            </div>

            <div className="option youtube-section">
              <h4>Processar Link do YouTube</h4>
              <input
                type="text"
                placeholder="Cole o link do YouTube aqui"
                value={youtubeUrl}
                onChange={handleYoutubeUrlChange}
              />
              <button onClick={handleYoutubeSubmit} disabled={!youtubeUrl}>
                Processar Link
              </button>
            </div>

            <div className="option record-section">
              <h4>Gravar Áudio</h4>
              <div className="record-controls">
                <button onClick={startRecording} disabled={isRecording}>
                  Gravar Áudio
                </button>
                <button onClick={stopRecording} disabled={!isRecording}>
                  Parar Gravação
                </button>
              </div>
            </div>
          </section>

          <section className="action-section">
            <button 
              onClick={handleUpload} 
              disabled={!file && !recordedAudio}
              className="send-button"
            >
              Enviar Arquivo
            </button>
          </section>

          <section className="status-section">
            <p className="status-text">Status: {status}</p>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <p className="progress-text">{progress}%</p>
            {result && (
                <div className="result-container">
                    <h2>Resultado da Transcrição:</h2>
                    <div>
                    {result.split("\n\n").map((paragrafo, index) => (
                        <p key={index}>{paragrafo}</p>
                    ))}
                    </div>
                </div>
                )}
          </section>
        </div>
      </main>
    </div>
  );
};

export default App;
